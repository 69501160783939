<template>
  <div class="w-full flex flex-col justify-center items-center gap-10">
    <Wrapper class="max-w-2xl">
      <h2 class="text-2xl text-left text-blue-dark"><b>Nueva solicitud</b></h2>
      <p class="mt-8 mb-2 text-left text-xs text-gray-400">
        Complete los campos solicitados y la información detallada.
      </p>
      <ComplaintForm
        class="w-full"
        @complaintSelected="handleComplaintSelected"
        :checkPersonalData="checkPersonalData"
        :checkSensitiveData="checkSensitiveData"
      />
      <div class="absolute top-0 right-12 mt-3 w-58 hover:min-w-34 max-w-xs">
        <a-affix :offset-top="120" class="row-span-3">
          <div class="w-full mb-4">
            <a-dropdown
              class="w-full px-6 py-2 rounded-lg flex justify-between items-center bg-blue-light cursor-pointer"
            >
              <p
                class="text-blue-dark text-left decoration-solid decoration-black"
              >
                ¿Quieres saber más? &nbsp;&nbsp;&nbsp;<a-icon type="down" />
              </p>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a
                    href="https://www.superfinanciera.gov.co/jsp/10112728"
                    target="_blank"
                    rel="noopener"
                    >Conciliación&nbsp;<b><a-icon type="right" /></b
                  ></a>
                </a-menu-item>
                <a-menu-item>
                  <a
                    href="https://www.superfinanciera.gov.co/jsp/61136"
                    target="_blank"
                    rel="noopener"
                    >Demanda&nbsp;<b><a-icon type="right" /></b
                  ></a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-affix>
      </div>
    </Wrapper>
    <div class="w-11/12 max-w-2xl" v-if="is_complaint_selected">
      <AccordionList :items="accordionList" />
    </div>
    <a-modal
      v-model="modalInfoVisible"
      centered
      :footer="null"
      :closable="false"
      @ok="() => (modalInfoVisible = false)"
    >
      <h1 class="mb-6 text-xl text-left text-blue"><b>Recuerde...</b></h1>
      <a-timeline>
        <a-timeline-item>
          En caso de que su inconformidad esté relacionada con un reporte de operadores 
          o centrales de información, es necesario que haya presentado una queja ante 
          la entidad vigilada o su Defensor del Consumidor Financiero.
        </a-timeline-item>
        
        <a-timeline-item
          >La información del Consumidor Financiero es indispensable para
          erradicar la inconformidad.</a-timeline-item
        >
        <hr class="my-4" />
        <p class="text-justify italic">
          De conformidad con lo dispuesto en la Ley 1581 de 2012, el Decreto
          1377 de 2013 y el Decreto 1074 de 2015, autorizo a la Superintendencia
          Financiera de Colombia (SFC) a realizar el tratamiento de los datos
          personales y/o sensibles necesario para el ejercicio de sus
          facultades, de conformidad con lo establecido en la Política de
          Tratamiento de datos de la SFC disponible en el siguiente
          <a class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" target="_blank" rel="noopener" href="https://www.superfinanciera.gov.co/publicaciones/10106783/nuestra-entidadacerca-de-la-sfcpoliticas-de-la-entidadpoliticas-web-10106783/">link</a>. El texto completo de la
          autorización se encuentra disponible en el siguiente
          <a
            href="https://www.superfinanciera.gov.co/loader.php?lServicio=Tools2&lTipo=descargas&lFuncion=descargar&idFile=1070896"
            class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
            >link</a
          >.
        </p>

        <form
          @submit.prevent="authorizePersonalDataProcessing"
          class="w-full px-0 py-0"
        >
          <div class="w-full flex justify-center">
            <div class="grid grid-cols-2 gap-4 w-full">
              <div class="flex justify-center w-full">
                <BaseRadio
                  class="w-full"
                  ref="personalData"
                  name="personalData"
                  id="personalData"
                  label="¿Autorizo el tratamiento de datos personales?"
                  :radioOptions="optionsYN"
                  v-model="checkPersonalData"
                  :required="true"
                />
              </div>
              <br />
              <div class="flex justify-center">
                <BaseRadio
                  ref="sensitiveData"
                  name="sensitiveData"
                  id="sensitiveData"
                  label="¿Autorizo el tratamiento de datos sensibles?"
                  :radioOptions="optionsYN"
                  v-model="checkSensitiveData"
                  :required="true"
                />
              </div>
            </div>
          </div>
          <br />

          <p v-if="feedback" class="text-xs text-center text-red-500">
            {{ feedback }}
          </p>

          <div class="flex justify-center mt-3 gap-4">
            <BaseButton
              type="submit"
              id="sendComplaint"
              label="ACEPTAR"
              class="w-1/3"
            />
            <a-button
              class="w-1/3"
              type="danger"
              shape="round"
              :block="true"
              ghost
              @click="onCancelModal"
            >
              CANCELAR
            </a-button>
          </div>
        </form>
      </a-timeline>
    </a-modal>
  </div>
</template>

<script>
import ComplaintForm from "@/components/Customer/Complaint/ComplaintForm";
export default {
  components: {
    ComplaintForm,
  },
  data() {
    return {
      modalInfoVisible: true,
      is_complaint_selected: false,
      accordionList: [
        {
          title: "Una vez presentada la queja, ¿qué hace la SFC?",
          body: "La Superintendencia Financiera de Colombia dará traslado de competencia de conformidad con el artículo 21 de la Ley 1775 de 2021. Quien resolverá tú queja será la entidad vigilada, ya que ésta es quien te presta de forma directa el producto o servicio.",
        },
        {
          title:
            "¿Qué notificaciones recibirá el consumidor financiero por parte de la SFC?",
          body: "Una vez registrada tu queja recibirás un correo electrónico con el código que te asignamos, informando que la misma fue trasladada a la entidad vigilada competente",
        },
      ],
      personalDataAuthorized: false,
      checkSensitiveData: 2,
      checkPersonalData: 2,
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      feedback: null,
    };
  },
  methods: {
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    handleComplaintSelected(newValue) {
      this.is_complaint_selected = newValue;
    },
    authorizePersonalDataProcessing() {
      if (this.checkPersonalData != 1) {
        // this.showToast("error","Debes aceptar el tratamiento de datos personales antes de continuar")
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      } else {
        this.personalDataAuthorized = true;
        this.modalInfoVisible = false;
      }
    },
    onCancelModal() {
      this.$router.go(-1);
    },
  },
  watch: {
    modalInfoVisible(newValue) {
      if (!newValue && !this.personalDataAuthorized) {
        this.showToast(
          "error",
          "Debes aceptar el tratamiento de datos personales antes de continuar"
        );
        this.modalInfoVisible = true;
      }
    },
    checkPersonalData(newValue) {
      if (newValue != 1) {
        this.feedback =
          "Debes aceptar el tratamiento de datos personales antes de continuar";
      }else{
        this.feedback = ""
      }
      // else if(this.checkSensitiveData == null){
      //   this.showToast("info","Por favor selecciona si deseas autorizar el tratamiento de datos sensibles")

      // }
    },
  },
};
</script>